export const SILVER_EGG_MERCHANT_ID = 'enoteca';

// 導入仕様書を参照
// https://docs.google.com/spreadsheets/d/1PmvMKh8Vbvmg2qYFDaX6rbjgFkFEs9Vw/edit#gid=1672524843
export const SPEC = {
  TOP_PC: 'pc111',
  TOP_SP: 'sp111',
  PRODUCT_LIST_PC: 'pc215', // 商品一覧 - PC
  PRODUCT_LIST_SP: 'sp215', // 商品一覧 - SP
  PROD_DETAIL_PC: 'pc311',
  PROD_DETAIL_SP: 'sp311',
  ARTICLE_DETAIL_PC: 'pc3111', // 読み物、特集、イベント共通
  ARTICLE_DETAIL_SP: 'sp3111', // 読み物、特集、イベント共通
  ARTICLE_RANKING_TOP_PC: 'pc131', // おどるワイントップ画面の記事ランキング - PC
  ARTICLE_RANKING_TOP_SP: 'sp131', // おどるワイントップ画面の記事ランキング - SP
  ARTICLE_RANKING_DETAIL_PC: 'pc3131', // おどるワイン詳細画面の記事ランキング - PC
  ARTICLE_RANKING_DETAIL_SP: 'sp3131', // おどるワイン詳細画面の記事ランキング - SP
  CART_PC: 'pc411',
  CART_SP: 'sp411',
  CART_IN_MODAL_PC: 'pc415', // カートインモーダル - PC
  CART_IN_MODAL_SP: 'sp415', // カートインモーダル - SP
  MYWINE_PC: 'pc611',
  MYWINE_SP: 'sp611',
  RECOMMEND_PC: 'pc811', // おすすめワイン(/recommend 画面) - PC
  RECOMMEND_SP: 'sp811', // おすすめワイン(/recommend 画面) - SP
  HISTORY_PC: 'pc921', // 閲覧履歴 - PC
  HISTORY_SP: 'sp921', // 閲覧履歴 - SP
  RANKING_PC: 'pc931', // ランキング - PC
  RANKING_SP: 'sp931', // ランキング - SP
};

export const REQUEST_NUM_SMALL = 6; // TOP、閲覧履歴（TOP、商品一覧、カート）
export const REQUEST_NUM = 12;
export const REQUEST_NUM_LARGE = 30; // 商品詳細、カートINモーダル、おすすめ一覧（/recommend）、閲覧履歴ページ（/history）
export const REQUEST_NUM_TOP_RANKING_PC = 5; // トップページPCのランキングでのリクエスト数
export const REQUEST_NUM_TOP_RANKING_SP = 3; // トップページSPのランキングでのリクエスト数
export const REQUEST_NUM_RANKING = 10; // ランキングページ（/ranking）でのリクエスト数
export const REQUEST_NUM_RANKING_DETAIL = 40; // ランキング詳細ページ（/ranking/~）でのリクエスト数
export const REQUEST_NUM_HISTORY_MENU = 5; // ハンバーガーメニューでのリクエスト数

export const CATEGORY_ID = {
  PRODUCT: 'item_all',
  WINE: 'wine_bottle',
  RED_WINE: 'color1',
  WHITE_WINE: 'color2',
  SPARKLING_WINE: 'color5',
  SPARKLING_RED_WINE: 'color12',
  SPARKLING_WHITE_WINE: 'color13',
  SPARKLING_ROSE_WINE: 'color14',
  WINE_SET: 'wine_set',
  ARTICLE: 'yomimono_all',
  SPECIAL_FEATURE: 'article_all',
  EVENT: 'event_all',
};

export const DEVICE = {
  PC: 'p',
  SP: 's',
};

// 初期状態の表示件数
export const DISPLAY_NUM = {
  ARTICLES_PC: 6,
  ARTICLES_SP: 4,
  ITEMS_PC: 6,
  ITEMS_PC_MYWINE: 4,
  ITEMS_SP: 4,
  ITEMS_TABLET: 4,
};

// ランキングのタブのインデックス
export const RANKING_TAB_INDEX = {
  RED_WINE: 0,
  WHITE_WINE: 1,
  SPARKLING_WINE: 2,
  WINE_SET: 3,
};

// ランキングのセクションのID
export const RANKING_SECTION_IDS = ['red', 'white', 'sparkling', 'set'];
